import React from "react"
import '../CSS/Contact.css'

const ContactPage = () => {
  return (
    <div className="Contact-Main">
      <h1 className="Contact-Header">Contact Us</h1>
      <p>Carole Fitsell</p>
      <p><a className="Body-Link" href="mailto:carole@dragonflylearning.com?subject=Hello Dragonfly Learning!">carole@dragonflylearning.com</a></p>
      <p className="Contact-Location">Penticton ・ Oliver ・ Osoyoos ・ Keremeos ・ Princeton</p>
    </div>
  )
}

export default ContactPage;
