import React from 'react';

const Home = () => {
  return (
    <div className="Home-Page">
      <h1 className="Home-h1">Welcome to Dragonfly Learning</h1>
      <h2 className="Home-h2"> Music & Tutoring in the South Okanagan</h2>
      <p className="Home-Body">We have a range of classes for all ages:
      <br/> Music Groups, Rhythm Circles, Academic Tutoring and English Language Improvement.
      <br/><br/> One-on-one sessions are also available.
      <br/><br/>If you’re interested in comfortable, creative tuition then Dragonfly Learning is for you.</p>
    </div>
  )
}

export default Home;
