import React, {Component} from 'react'
import '../CSS/Gallery.css'
import Thumbs from './Gallery/Thumbs.js'

class GalleryPage extends Component {
  state = {
    show: false
    }
  showModal = () => {
    console.log(this.state)
    this.setState({show:true})
  };
  hideModal = () => {
    this.setState({show:false})
  };
  render() {
    return (
      <div className="Gallery-Page">
        <h1 className="Gallery-Header">Gallery</h1>
        <div className="Top-Line">
        <Thumbs onClick={this.showModal}/>
        </div>
      </div>
    )
  };
}

export default GalleryPage;
