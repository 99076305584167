import React, {Component} from 'react'
import XyloBoy from '../../../img/xyloboy.jpg'
import RecLine from '../../../img/KRE/lineup.png'
import Recorders from '../../../img/KRE/gristxmas.JPG'
import Bench from '../../../img/SP2019/bench.jpg'
import Whisper from '../../../img/SP2019/whisper.jpg'
import Dariusin from '../../../img/KRE/dariusincl.JPG'
import MuseStep from '../../../img/SP2019/museumsteps.jpg'
import Museum from '../../../img/SP2019/museum.jpg'
import Plate from '../../../img/SP2019/platedinos.jpg'
import Paintgroup from '../../../img/SP2019/grouppainting.jpg'
import Sunflower from '../../../img/SP2019/sunflower.jpg'
import Group2016 from '../../../img/KRE/group2016.JPG'

class Thumbs extends Component {
  state = {
    imgs: [
      {id:1, source: RecLine, alt: "Our Recorders!", text: "Our Recorders!"},
      {id:2, source: Recorders, alt: "Christmas Concert", text: "Christmas Concert"},
      {id:3, source: Dariusin, alt: "Keremeos Recorder Ensemble", text: "Keremeos Recorder Ensemble"},
      {id:4, source: Group2016, alt: "Summer Program 2019", text: "Keremeos Recorder Ensemble 2016"},
      {id:5, source: XyloBoy, alt: "Musical Footsteps", text: "Musical Footsteps"},
      {id:6, source: Whisper, alt: "Summer Program 2019", text: "Summer Program 2019"},
      {id:7, source: Bench, alt: "Summer Program 2019", text: "Summer Program 2019"},
      {id:8, source: MuseStep, alt: "Summer Program 2019", text: "Summer Program 2019"},
      {id:9, source: Museum, alt: "Summer Program 2019", text: "Summer Program 2019"},
      {id:10, source: Plate, alt: "Summer Program 2019", text: "Summer Program 2019"},
      {id:11, source: Sunflower, alt: "Summer Program 2019", text: "Summer Program 2019"},
      {id:12, source: Paintgroup, alt: "Summer Program 2019", text: "Summer Program 2019"}
    ]}

  render(){
    return (
      <div>
        { this.state.imgs && this.state.imgs.map(img => {
          return (
            <div className="Img-Container">
              <img src={img.source} alt={img.alt} className="Photo-Thumb" />
              <div className="overlay">
                <div className="text">{img.text}</div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default Thumbs
