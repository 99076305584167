import React from 'react'
import { NavLink } from 'react-router-dom'
import LogoImg from '../../img/logo.png'

const Logo = () => {
  return (
    // <div>
    //   <NavLink to='/'><p className="Logo-Text">DRAGONFLY LEARNING</p></NavLink>
    // </div>
     <div className="logo container">
      <div className="logo">
        <NavLink to='/'><div className="wing" id="wing-1"></div>
        <div className="wing"></div>
        <h1 className="title">Dragonfly Learning</h1>
        {/* <div class="dragonfy-icon"> */}
          <img className="dragonfy-icon" src={LogoImg} alt='dragonfly learning' />
        {/* </div> */}</NavLink>
      </div>
    </div>
  )
}

export default Logo
