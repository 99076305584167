import React from "react"
import "../CSS/About.css"
import Dragonfly from "../../img/DFpainting.jpg"

const AboutPage = () => {
  return (
    <div className="About-Content">
      <img src={Dragonfly} alt="Lily's Dragonfly Painting" className="About-Photo" />
      <div className="About-Text">
        <h1 className="About-Header">About</h1>
        <p>The dragonfly shows us that making the most of every moment is important, as its existence as a dragonfly is very short. The transformation to a dragonfly is quite spectacular. After a few years living as an underwater nymph, the beautiful dragonfly emerges, quiet, graceful and magnificent to watch. Hidden beneath this exterior is a strong, instinctive creature, a powerful flying machine with amazing vision. The iridescent nature of its wings and body remind us that we can decide how and from which angle we show ourselves, revealing different colours at different times. We can grow freely and gracefully, learning as we go. What is on the surface is an image. What lies beneath will be the foundation for producing something that is truly beautiful.</p>
          <p>Dragonfly Learning realises that because everybody is different, each person will need a unique learning path. We find out what inspires each person to learn and look for a suitable way for them to build a solid foundation from which to grow.</p>
          <p>By creating an environment where students can openly express themselves, Dragonfly Learning provides the materials and guidance necessary for them to learn in a way and at a pace which they feel comfortable with. They will then be ready to fly and produce something that they are proud of.</p>
      </div>
    </div>
  )
}

export default AboutPage;
