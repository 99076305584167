import React, {Component} from "react"
import "../CSS/Teaching.css"
import IndoorGroup from '../../img/indoorgroup.jpg'
import TwoOutside from '../../img/twooutside.jpg'
import GroupC from '../../img/KRE/groupchurch.jpg'
import Dflogo from '../../img/DFLlogos.png'
import Dflogo2 from '../../img/DFLogos2.png'

class TeachingPage extends Component {
  state = {
    MF: false,
    OOO: false,
    TT: false,
    SG: false,
    SP: false
    }

    mfClick = (e) => {
      this.setState({
        MF: !this.state.MF,
        OOO: false,
        TT: false,
        SG: false,
        SP: false
      })
    }
    oooClick = (e) => {
      this.setState({
        OOO: !this.state.OOO,
        MF: false,
        TT: false,
        SG: false,
        SP: false
      })
    }
    ttClick = (e) => {
      this.setState({
        TT: !this.state.TT,
        MF: false,
        OOO: false,
        SG: false,
        SP: false
      })
    }
    sgClick = (e) => {
      this.setState({
        SG: !this.state.SG,
        MF: false,
        OOO: false,
        TT: false,
        SP: false
      })
    }
    spClick = (e) => {
      this.setState({
        SP: !this.state.SP,
        MF: false,
        OOO: false,
        TT: false,
        SG: false
      })
    }

  render(){
    return (
    <div className="Teaching-Container">
      <div className="Teaching-Image-Container-Mobile">
        <img src={IndoorGroup} alt={IndoorGroup} className="Teaching-Photo-Thumb-Mobile" />
        <img src={TwoOutside} alt={TwoOutside} className="Teaching-Photo-Thumb-Mobile" />
        <img src={GroupC} alt={GroupC} className="Teaching-Photo-Thumb-Mobile" />
      </div>
      <div className="Teaching-Text-Container">
        <h1 className="Teaching-Header">Teaching</h1>
        <span className="Teaching-Section">
          <p className={this.state.MF ? "Teaching-Section-Title Teaching-Section-Title-Active" : "Teaching-Section-Title"} onClick={this.mfClick}>Musical Footsteps</p>
          <p className={this.state.MF ? "Teaching-Info" : "Teaching-Info Teaching-Hide"}>
            <p className="Teaching-Quote">“Since the beginning of time, children have not liked to study.
            They would much rather play, and if you have their interests at heart,
            you will let them learn while they play; they will find that what they have mastered is child’s play.”<br /> – Carl Orff</p>
            Musical Footsteps is a group class for children 3 to 6 years of age using songs,
            stories, instruments and visual clues.
            Literacy, numeracy and note reading are gently introduced into a syllabus of lessons inspired by Orff and Kodaly,
            which encourage improvisation and learning through fun.
            <br/><br/><a className="Body-Link" href="https://pentictonacademyofmusic.ca//programs/?section=group-lessons#musical-footsteps" target="_blank"><b>Penticton</b><br/> Saturdays 10:00 - 10:45am</a>
            <br/><a className="Body-Link" href=" https://www.google.com/maps/place/Cawston+Primary+School/@49.1799317,-119.7612534,15z/data=!4m2!3m1!1s0x0:0xcb087792a4918db?sa=X&ved=2ahUKEwj68eCr4KbkAhWDIDQIHW7dB14Q_BIwCnoECA0QCA" target="_blank"><b>Cawston</b><br/>Tuesdays @StrongStart 9:15 – 10:00am</a>
            <br/><b>Keremeos </b><br/>TBD
          </p>
        </span>
        <span className="Teaching-Section">
          <p className={this.state.OOO ? "Teaching-Section-Title Teaching-Section-Title-Active" : "Teaching-Section-Title"} onClick={this.oooClick}>One-on-one Music Lessons</p>
          <p className={this.state.OOO ? "Teaching-Info" : "Teaching-Info Teaching-Hide"}>Dragonfly Learning offers individual lessons in piano, recorder and oboe.
            Younger children are taken through the Musical Footsteps program, eventually progressing onto the piano.
            They have fun while learning and building a foundation to the next stage.</p>
        </span>
        <span className="Teaching-Section">
          <p className={this.state.TT ? "Teaching-Section-Title Teaching-Section-Title-Active" : "Teaching-Section-Title"} onClick={this.ttClick}>Tutoring</p>
          <p className={this.state.TT ? "Teaching-Info" : "Teaching-Info Teaching-Hide"}>Dragonfly Learning offers tutoring to children who need help in their academic studies.
            By creating an environment where students can express themselves honestly,
            Dragonfly Learning provides the materials and guidance necessary for them to learn at a pace they feel comfortable with.</p>
        </span>
        <span className="Teaching-Section"><p className={this.state.SP ? "Teaching-Section-Title Teaching-Section-Title-Active" : "Teaching-Section-Title"} onClick={this.spClick}>Summer Program</p>
        <p className={this.state.SP ? "Teaching-Info" : "Teaching-Info Teaching-Hide"}>Each summer, Dragonfly Learning, in partnership with L.S.C.S.S. and R.D.O.S., provides week long day programs
          which focus on creative activities within the “Arts.”
          <br /><br />The Grist Mill has been the perfect venue for local children to come and work with different facilitators and soak in the ambience of their surroundings, as well as participating in outside activities.
          Each year an excursion is also included.
          <br /><br />The aim is to include as many children as possible and funds are raised to provide bursaries where necessary.
          <br />2020 will mark the third annual Summer Program!! watch this space for more details.
          <br /><br />Special thanks go to:
          <br /> <img src={Dflogo} alt="Thank yous" className="dflogo" />
          <br />for their funding and partnership <br />and:
          <br /> <img src={Dflogo2} alt="Thank yous" className="dflogo" />
          <br /> for help with fundraising.
          <br /> Without their support, this program would not be such a huge success!
        </p>
        </span>
      </div>
      <div className="Teaching-Image-Container">
        <img src={IndoorGroup} alt={IndoorGroup} className="Teaching-Photo-Thumb" />
        <img src={TwoOutside} alt={TwoOutside} className="Teaching-Photo-Thumb" />
        <img src={GroupC} alt={GroupC} className="Teaching-Photo-Thumb" />
      </div>
    </div>
  )
}
}

export default TeachingPage;
