import React from 'react'
import { NavLink } from 'react-router-dom'

const Nav = ({toggleState, toggleMain, toggleMenu}) => {
    return (
      <div className="Nav-Bar">
        <button className={toggleState ? "Hide Mobile-Menu" : "Mobile-Menu-Button Mobile-Menu"} onClick={toggleMain}>Menu</button>
        <button className={toggleState ? "Close-Menu Mobile-Menu" : "Hide Mobile-Menu"} onClick={toggleMain}>X</button>
        <ul className={toggleState ? "Nav-Links" : "Nav-Links Hide"}>
          <div className="Menu-Div">
            <li><NavLink onClick={toggleMenu} to='/about' className="Nav-Link" activeClassName="Link-Active"><p className="Nav-Link-Text">About</p></NavLink></li>
            <li><NavLink onClick={toggleMenu} to='/teaching' className="Nav-Link" activeClassName="Link-Active"><p className="Nav-Link-Text">Teaching</p></NavLink></li>
          </div>
          <div className="Menu-Div">
            <li><NavLink onClick={toggleMenu} to='/gallery' className="Nav-Link" activeClassName="Link-Active"><p className="Nav-Link-Text">Gallery</p></NavLink></li>
            <li><NavLink onClick={toggleMenu} to='/contact' className="Nav-Link" activeClassName="Link-Active"><p className="Nav-Link-Text">Contact</p></NavLink></li>
          </div>
        </ul>
      </div>
    )
}

export default Nav
