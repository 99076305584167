import React, {Component} from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Home from './components/content/Home'
import About from './components/content/About'
import Contact from './components/content/Contact'
import Gallery from './components/content/Gallery'
import Teaching from './components/content/Teaching'


class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <div className="App App-main">
            <Header changePage={this.aboutPageChangeState}/>
            <div className="app-mid">
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/about' component={About} />
              <Route path='/gallery' component={Gallery} />
              <Route path='/teaching' component={Teaching} />
              <Route path='/contact' component={Contact} />
            </Switch>
            </div>
            <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
