import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="App-Footer">
      <NavLink to='/contact'><p id="Footer-Top-Line">Contact us today to book a spot</p><p>  •  </p><p>The first lesson is on us!</p></NavLink>
    </div>
  )
}

export default Footer
