import React, { Component } from 'react'
import Nav from '../header/Nav'
import Logo from '../header/Logo'

class Header extends Component{
  state = {
    toggle: false
  }

  handleClick = (e) => {
    console.log(this.state)
    this.setState({
      toggle: !this.state.toggle
    })
  }
  menuClick = (e) => {
    console.log(this.state)
    this.setState({
      toggle: false
    })
  }
  render() {
    return (
      <div className={this.state.toggle ? "App-Header Menu-Open" : "App-Header"}>
        <Logo />
        <Nav toggleState={this.state.toggle} toggleMain={this.handleClick} toggleMenu={this.menuClick} />
      </div>
    );
  }
}

export default Header;
